@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    background-color: #000080ff;
}

.w-blue {
    background-color: #000080ff;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
    color: white;
}